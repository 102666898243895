<template>
  <div ref="container" style="height:100%;position: relative;overflow-y: auto;">
    <div style="padding: 0px;background-color: #fff">
      <div  class="block" style="padding-top: 0px;padding-bottom: 0px;" >
        <a-tabs v-model:activeKey="tab_activeKey" @change="changeTabPage">
          <a-tab-pane key="informPlatformContainer" tab="通知平台配置"></a-tab-pane>
          <a-tab-pane key="informWorkOrderSet" tab="通知模块配置"></a-tab-pane>
          <a-tab-pane key="kbsSysSet" tab="知识库流程配置"></a-tab-pane>
        </a-tabs>
      </div>

      <div class="block" style="" >
        <transition mode="out-in" leave-active-class="cmp-out" enter-active-class="cmp-in">
          <Component :is="cmpObj"/>
        </transition>
      </div>


    </div>
  </div>
</template>

<script setup>
import {defineAsyncComponent, ref, shallowRef} from "vue";
/** 异步加载组件 **/
const InformPlatformContainer = defineAsyncComponent(() =>{
  return import('@/components/systemSet/_informPlatformContainer.vue')
})
const InformWorkOrderSet = defineAsyncComponent(() =>{
  return import('@/components/systemSet/_informWorkOrderSet.vue')
})
const KbsSysSet = defineAsyncComponent(()=>{
  return import('@/components/systemSet/_kbsSysSet.vue')
})

const tab_activeKey = ref("informPlatformContainer");
const cmpObj = shallowRef(InformPlatformContainer);

const changeTabPage = ()=>{
  if (tab_activeKey.value === "informPlatformContainer"){
    cmpObj.value = InformPlatformContainer;
  }else if (tab_activeKey.value === "informWorkOrderSet"){
    cmpObj.value = InformWorkOrderSet;
  }else if (tab_activeKey.value === "kbsSysSet"){
    cmpObj.value = KbsSysSet;
  }

}

</script>

<style scoped>
.block{
  box-sizing:border-box;
  padding-left: 10px;
  padding-right: 10px;
}
.cmp-in {
  animation: fadeIn;
  animation-duration: 0.2s; /* don't forget to set a duration! */
}
.cmp-out{
  animation: fadeOut;
  animation-duration: 0.2s;
}
</style>
