<template>
  <a-layout-sider v-model:collapsed="collapsed" :trigger="null" breakpoint="lg" collapsed-width="50" collapsible>
    <div class="logo-warp">
      <img src="../../../public/logo/logo.png">
      <div class="title" v-if="collapsed==false">运维管理系统</div>
    </div>
    <div style="height:calc(100vh - 44px);overflow-y: auto;">
      <a-menu
          v-model:selectedKeys="selectedKeys"
          mode="inline"
          theme="dark"
          :inline-collapsed="collapsed"
          :openKeys="openKeys"
          @click="handleClick"
      >
        <div v-permission="'homePage'">
          <a-menu-item key="1" path="/home">
            <template #icon><DesktopOutlined /></template>
            <span>工作台</span>
          </a-menu-item>
        </div>
<!--        <div>
          <a-menu-item key="3" path="/alarmCenter">
            <template #icon><alert-outlined /></template>
            <span>告警中心</span>
          </a-menu-item>
        </div>-->
        <div v-permission="'inform'">
          <a-sub-menu key="sub9">
            <template #icon><message-outlined /></template>
            <template #title>通知中心</template>
            <div v-permission="'message'"><a-menu-item key="91" path="/inform/message" ><template #icon><menu-outlined /></template>消息通知</a-menu-item></div>
          </a-sub-menu>
        </div>
        <div v-permission="'workOrder'">
          <a-sub-menu key="sub2">
            <template #icon><ContainerOutlined /></template>
            <template #title>工单中心</template>
            <div v-permission="'workOrderUnfinished_all'"><a-menu-item key="21" path="/workOrder/workOrderUnfinished" ><template #icon><menu-outlined /></template>未完成工单</a-menu-item></div>
            <div v-permission="'workOrderFinished_all'"><a-menu-item key="22" path="/workOrder/workOrderFinished" ><template #icon><menu-outlined /></template>已完成工单</a-menu-item></div>
          </a-sub-menu>
        </div>
        <div v-permission="'assetCenter'">
          <a-sub-menu key="sub5">
            <template #icon><gold-outlined /></template>
            <template #title>资产中心</template>
            <div v-permission="'assetManage'"><a-menu-item key="51" path="/assetCenter/assetManage" ><template #icon><menu-outlined /></template>资产管理</a-menu-item></div>
            <div v-permission="'assetChangeLog'"><a-menu-item key="52" path="/assetCenter/assetChangeLog" ><template #icon><menu-outlined /></template>资产变更记录</a-menu-item></div>
            <div v-permission="'assetRepairedLog'"><a-menu-item key="53" path="/assetCenter/assetRepairedLog" ><template #icon><menu-outlined /></template>资产维修记录</a-menu-item></div>
            <div v-permission="'inspectRecordManage'"><a-menu-item key="54" path="/assetCenter/inspectRecord" ><template #icon><menu-outlined /></template>巡检记录</a-menu-item></div>
          </a-sub-menu>
        </div>
        <div v-permission="'statementCenter'">
          <a-sub-menu key="sub8">
            <template #icon><bar-chart-outlined /></template>
            <template #title>报表中心</template>
            <div v-permission="'statementWo'"><a-menu-item key="81" path="/statementCenter/statementWo" ><template #icon><menu-outlined /></template>工单报表</a-menu-item></div>
            <div v-permission="'statementDept'"><a-menu-item key="82" path="/statementCenter/statementDept" ><template #icon><menu-outlined /></template>部门报表</a-menu-item></div>
            <div v-permission="'statementUser'"><a-menu-item key="83" path="/statementCenter/statementUser" ><template #icon><menu-outlined /></template>员工报表</a-menu-item></div>
            <div v-permission="'statementAsset'"><a-menu-item key="84" path="/statementCenter/statementAsset" ><template #icon><menu-outlined /></template>资产报表</a-menu-item></div>
            <div v-permission="'statementType'"><a-menu-item key="85" path="/statementCenter/statementType" ><template #icon><menu-outlined /></template>类型报表</a-menu-item></div>
            <div v-permission="'statementInspect'"><a-menu-item key="86" path="/statementCenter/statementInspect" ><template #icon><menu-outlined /></template>巡检报表</a-menu-item></div>
          </a-sub-menu>
        </div>
        <div v-permission="'kbsCenter'">
          <a-sub-menu key="sub11">
            <template #icon><read-outlined /></template>
            <template #title>知识库中心</template>
            <div v-permission="'kbsCenter_all'"><a-menu-item key="111" path="/kbs/kbsCenter" ><template #icon><menu-outlined /></template>知识库查询</a-menu-item></div>
          </a-sub-menu>
        </div>
        <div v-permission="'project'">
          <a-sub-menu key="sub6">
            <template #icon><project-outlined /></template>
            <template #title>项目中心</template>
            <div v-permission="'projectManage'"><a-menu-item key="60" path="/project/projectManage" ><template #icon><menu-outlined /></template>项目管理</a-menu-item></div>
          </a-sub-menu>
        </div>
        <div v-permission="'base'">
          <a-sub-menu key="sub10">
            <template #icon><database-outlined /></template>
            <template #title>基础数据</template>
            <div v-permission="'assetBase'"><a-menu-item key="101" path="/base/assetBase" ><template #icon><menu-outlined /></template>资产数据</a-menu-item></div>
            <div v-permission="'workOrderBase'"><a-menu-item key="102" path="/base/workOrderBase" ><template #icon><menu-outlined /></template>工单数据</a-menu-item></div>
            <div v-permission="'inspectBase'"><a-menu-item key="103" path="/base/inspectBase" ><template #icon><menu-outlined /></template>巡检数据</a-menu-item></div>
            <div v-permission="'kbsBase'"><a-menu-item key="104" path="/base/kbsBase" ><template #icon><menu-outlined /></template>知识库数据</a-menu-item></div>
          </a-sub-menu>
        </div>
        <div v-permission="'system'">
          <a-sub-menu key="sub3">
            <template #icon><setting-outlined /></template>
            <template #title>系统管理</template>
            <div v-permission="'dept_all'"><a-menu-item key="30" path="/system/dept" ><template #icon><menu-outlined /></template>部门管理</a-menu-item></div>
            <div v-permission="'user_all'"><a-menu-item key="31" path="/system/user" ><template #icon><menu-outlined /></template>用户管理</a-menu-item></div>
            <div v-permission="'visitor_all'"><a-menu-item key="32" path="/system/visitor" ><template #icon><menu-outlined /></template>访客管理</a-menu-item></div>
            <div v-permission="'role_all'"><a-menu-item key="33" path="/system/role" ><template #icon><menu-outlined /></template>角色管理</a-menu-item></div>
            <div v-permission="'operation_all'"><a-menu-item key="34" path="/system/operation" ><template #icon><menu-outlined /></template>权限管理</a-menu-item></div>
            <div v-permission="'systemSet'"><a-menu-item key="35" path="/system/systemSet" ><template #icon><menu-outlined /></template>系统配置</a-menu-item></div>
            <div><a-menu-item key="36" path="/system/licenseSet" ><template #icon><menu-outlined /></template>软件授权</a-menu-item></div>
          </a-sub-menu>
        </div>
        <div v-permission="'myWorkOrder'">
          <a-sub-menu key="sub7">
            <template #icon><user-switch-outlined /></template>
            <template #title>我的工单</template>
            <div v-permission="'myWorkOrder_pending'"><a-menu-item key="71" path="/myWorkOrder/pending" ><template #icon><menu-outlined /></template>待处理工单</a-menu-item></div>
            <div v-permission="'myWorkOrder_finished'"><a-menu-item key="72" path="/myWorkOrder/finished" ><template #icon><menu-outlined /></template>已完成工单</a-menu-item></div>
            <div v-permission="'myWorkOrder_report'"><a-menu-item key="73" path="/myWorkOrder/report" ><template #icon><menu-outlined /></template>已报障工单</a-menu-item></div>
            <div v-permission="'myWorkOrder_created'"><a-menu-item key="74" path="/myWorkOrder/created" ><template #icon><menu-outlined /></template>已创建工单</a-menu-item></div>
            <div v-permission="'myWorkOrder_uncomment'"><a-menu-item key="75" path="/myWorkOrder/uncomment" ><template #icon><menu-outlined /></template>待评价工单</a-menu-item></div>
          </a-sub-menu>
        </div>
        <div v-permission="'myKbs'">
          <a-sub-menu key="sub_mykbs">
            <template #icon><user-switch-outlined /></template>
            <template #title>我的知识库</template>
            <div v-permission="'myKbs_pending'"><a-menu-item key="mykbs01" path="/myKbs/pending" ><template #icon><menu-outlined /></template>待审批知识库</a-menu-item></div>
            <div v-permission="'myKbs_pending'"><a-menu-item key="mykbs02" path="/myKbs/edited" ><template #icon><menu-outlined /></template>已编辑知识库</a-menu-item></div>

          </a-sub-menu>
        </div>
        <div v-permission="'personal'">
          <a-sub-menu key="sub1">
            <template #icon><user-outlined /></template>
            <template #title>个人中心</template>
            <div v-permission="'myInfo'"><a-menu-item key="12" path="/personal/myInfo" ><template #icon><menu-outlined /></template>个人信息</a-menu-item></div>
          </a-sub-menu>
        </div>
        <div v-permission="'backstage'">
          <a-sub-menu key="sub4">
            <template #icon><AppstoreOutlined /></template>
            <template #title>后台管理</template>
            <div v-permission="'tenant_request'"><a-menu-item key="41" path="/backstage/tenantRequest"><template #icon><menu-outlined /></template>租户申请</a-menu-item></div>
            <div v-permission="'tenant_all'"><a-menu-item key="42" path="/backstage/tenant"><template #icon><menu-outlined /></template>租户管理</a-menu-item></div>
          </a-sub-menu>
        </div>
      </a-menu>
    </div>
  </a-layout-sider>
</template>

<script>
import { UserOutlined, SettingOutlined, DesktopOutlined, AppstoreOutlined,MenuOutlined,ContainerOutlined,BarChartOutlined
  ,DatabaseOutlined,MessageOutlined,GoldOutlined,ProjectOutlined,UserSwitchOutlined,ReadOutlined } from '@ant-design/icons-vue';
import {onMounted, ref, watch} from 'vue';
import { useRouter, useRoute } from 'vue-router';
//import {useNavTagsStore} from "@/framework/store/NavTagsStore"

export default {
  components: {
    UserOutlined,
    SettingOutlined,
    DesktopOutlined,
    AppstoreOutlined,
    MenuOutlined,
    ContainerOutlined,
    BarChartOutlined,
    DatabaseOutlined,
    MessageOutlined,
    GoldOutlined,
    ProjectOutlined,
    UserSwitchOutlined,
    ReadOutlined
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const collapsed = ref(false);
    const selectedKeys = ref(['1']);
    const openKeys = ref([]);
    //const navTagsStore = useNavTagsStore();

    const handleClick = function (menu){
      router.push(menu.item.path);
    };

    onMounted(()=>{
      //console.log("FrameMenu  onMounted ---- in",frame.test.value);
      setMenuData();
    })

    /*onBeforeRouteUpdate(to=>{
      //console.log("FrameMenu  onBeforeRouteUpdate ---- in",to);
    })*/


    watch(()=>{return route.matched},()=>{
      //console.log("watch ---- in");
      //console.log("watch - doSelKey",router.currentRoute.value.path);
      //console.log("watch ---- ",route.matched);
      setMenuData();
    },{deep:true})

    let setMenuData = function (){
      if (route.matched){
        if (route.matched.length >= 2){
          let parentRoute = route.matched[1];
          let curRoute = route.matched[route.matched.length-1];
          if (curRoute?.path){
            openKeys.value[0] = parentRoute.meta.menuKey;
            selectedKeys.value[0] = curRoute.meta.menuKey;
            //console.log("[FrameModule]-watch ---- 1");

            //navTagsStore.setNavTagsParms(curRoute.meta.menuKey,curRoute.meta.title,curRoute.path);
          }
        }else{
          let curRoute = route.matched[1];
          if (curRoute?.path){
            openKeys.value[0] = curRoute.meta.menuKey;
            selectedKeys.value[0] = curRoute.meta.menuKey;
            //console.log("[FrameModule]-watch ---- 2",curRoute);

            //navTagsStore.setNavTagsParms(curRoute.meta.menuKey,curRoute.meta.title,curRoute.path);
          }
        }
      }
    }

    /** 父组件(App.vue)方法onMenuCollapse调用此子组件的方法**/
    const menuCollapsed = function (){
      collapsed.value = !collapsed.value;
    }

    return {
      selectedKeys,
      collapsed,
      openKeys,
      handleClick,
      menuCollapsed
    };

  },
}
</script>

<style scoped>
  .logo-warp{
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px dashed #d0cece;
  }
  .logo-warp img{
    width: 30px;
    height: 30px;
  }
  .logo-warp .title{
    color: #ffffff;
    font-weight: 700;
    margin-left: 5px;
  }
</style>
