import {axios} from "@/framework/utils/request";
//import qs from "qs";

const api_licensetSet = {
    getLicenseInfo:()=>{
        return axios({
            url:"/licenseSet/getLicenseInfo",
            method:"get",
            params:{
            }
        })
    },
    activateFile:function (data){
        return axios({
            url:"/licenseSet/activateFile",
            method:"post",
            data:data
        })
    },
    activateCode:(data)=>{
        return axios({
            url:"/licenseSet/activateCode",
            method:"post",
            data:data
        })
    },
}


export default api_licensetSet;