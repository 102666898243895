<template>
  <div ref="container" style="height:100%;position: relative;overflow-y: auto;">
    <div style="padding: 0px;background-color: #fff">
      <div class="block" style="" >
        <div style="display: flex;box-sizing:border-box;height: 60px;" >
          <div style="flex: 5;display: flex;align-items: center;padding-left: 10px;">
            <a-form ref="formSearchRef" layout="inline" :model="formSearchState" name="search">
              <div >
                <a-space :size="5">
                  <a-form-item name="orderNo">
                    <a-input v-model:value="formSearchState.orderNo" allowClear placeholder="工单号" size="default" />
                  </a-form-item>
                  <a-form-item name="faultOverview">
                    <a-input v-model:value="formSearchState.faultOverview" allowClear placeholder="故障描述" size="default" />
                  </a-form-item>
                  <a-form-item name="searchTimeRange" >
                    <a-range-picker show-time v-model:value="searchTimeRange" format="YYYY-MM-DD HH:mm:ss" :locale="locale" size="default"/>
                  </a-form-item>
                  <a-form-item>
                    <CustomInputProject @callbackSelVal="callback_selProject"/>
                  </a-form-item>
                  <a-form-item name="flowType">
                    <a-select v-model:value="formSearchState.status" style="width: 100px"  size="default">
                      <a-select-option value="">全部状态</a-select-option>
                      <a-select-option value="-1">处理中的</a-select-option>
                      <a-select-option :value="Constant.WORK_ORDER_FLOW_TYPE_FINISHED">已完成的</a-select-option>
                      <template v-for="(item,key) in flowTypeObj" :key="item">
                        <a-select-option v-if="parseInt(key) !== Constant.WORK_ORDER_FLOW_TYPE_FINISHED" :value="key">{{ item }}</a-select-option>
                      </template>
                    </a-select>
                  </a-form-item>
                  <a-form-item>
                    <a-button type="primary" @click="onSearch()" size="default" style="margin-left: 0px;"><template #icon><SearchOutlined /></template>搜索</a-button>
                  </a-form-item>
                </a-space>
              </div>

            </a-form>
          </div>

          <div style="flex: 1;display: flex;justify-content: flex-end;align-items: center;padding-right: 10px;">
            <a-button style="margin-right: 10px;" type="primary" size="default" @click="showCreateWorkOrder()"><template #icon><file-word-outlined /></template>工单申请</a-button>
          </div>
        </div>
        <!-- :scroll 设置y值高度可实现表头固定滚动，整个body100vh - 头部header高度90 - FrameMain=>content Margin 5px - search 50 - 表头高度40 - 分页高度52 -->
        <a-table
            class="ant-table-striped"
            :columns="columns"
            :data-source="dataSource"
            :pagination="false"
            :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
            :bordered="true"
            size="small"
            :loading="loading"
            :scroll="{ x: 1200, y: 'calc(100vh - 247px)' }"
        >
          <template #bodyCell="{ column,record }">
            <template v-if="column.dataIndex === 'operation'">
              <div style="display: flex;justify-content: left;">
                <a-button style="margin: 0px 4px;" type="link" size="small" @click="showViewWorkOrder(record.id)"><template #icon><eye-outlined /></template><span style="margin-left:3px;">查看</span></a-button>
              </div>
            </template>
            <template v-if="column.dataIndex === 'faultOverview'">
              <a-tooltip :title="record.faultOverview" placement="topLeft">{{record.faultOverview}}</a-tooltip>
            </template>
            <template v-else-if="column.dataIndex === 'reportUser'">
              {{record.reportUser?.name ? record.reportUser?.name : record.reportUserName}}
            </template>
            <template v-else-if="column.dataIndex === 'statusMap'">
              <!--            <div :style="'width:' + record.flowType.minSize*20 +'%'">
                            <template v-for="(items,index) in record.flowType.list" :key="index">
                              <a-steps size="small">
                                <template v-for="(item,index1) in items" :key="index1">
                                  <a-step status="finish" :title="item"/>
                                </template>
                              </a-steps>
                            </template>
                          </div>-->

              <a-badge status="success" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_FINISHED" text='已完成' />
              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_REPORT" :text='flowTypeObj[record.statusMap.code]' />
              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_DISPATCH" :text='flowTypeObj[record.statusMap.code]'/>
              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_ASSIGN" :text='flowTypeObj[record.statusMap.code]'/>
              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_REJECT" :text='flowTypeObj[record.statusMap.code]'/>
              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_RECEIVE" :text='flowTypeObj[record.statusMap.code]'/>
              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_ARRIVED" :text='flowTypeObj[record.statusMap.code]'/>
              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_TRANSFER" :text='flowTypeObj[record.statusMap.code]'/>
              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_DELAY" :text='flowTypeObj[record.statusMap.code]'/>
              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_COOPERATE" :text='flowTypeObj[record.statusMap.code]'/>
            </template>
            <template v-else-if="column.dataIndex === 'commentMap'">
              {{record.commentMap.value}}
            </template>
          </template>
        </a-table>
        <div style="padding:10px;text-align: center">
          <a-pagination v-model:current="pagination.current" :total="pagination.total" :page-size="pagination.pageSize"
                        @change="onPageChange" @showSizeChange="onShowSizeChanger" :show-total="total => `共 ${total} 条`"
                        :page-size-options="['10','20','30','40','50']" show-size-changer>
            <template #buildOptionText="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>

      <!--    工单申请弹窗组件     -->
      <CreateWorkOrder ref="createWorkOrder" @setCallback="callback_CreateWorkOrder"></CreateWorkOrder>
      <!--    工单查看     -->
      <ViewWorkOrder ref="viewWorkOrderRef" :workOrderId="viewWorkOderId" @callbackViewWorkOrder="callbackViewWorkOrder" v-if="isShowViewWorkOrder"></ViewWorkOrder>

    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, reactive, defineAsyncComponent,} from "vue";
import api_workOrder from "@/api/workOrder";
import {message} from "ant-design-vue";
import clone from "clone";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import {flowTypeObj,Constant} from "@/js/common";
import {
  SearchOutlined,
  FileWordOutlined,
  EyeOutlined,
} from "@ant-design/icons-vue";

dayjs.locale('zh-cn');

/** 异步加载组件 **/
const CreateWorkOrder = defineAsyncComponent(() =>{
  return import('@/components/_createWorkOrder')
})
/** 异步加载组件 **/
const ViewWorkOrder = defineAsyncComponent(() =>{
  return import('@/components/_viewWorkOrder')
})
const CustomInputProject = defineAsyncComponent(() =>{
  return import('@/components/_customInputProject.vue')
})

const viewWorkOderId = ref(null);       //要查看工单的id
const type = Constant.MY_WORK_ORDER_CREATED; //tab面板默认的值
const loading = ref(false);
const isShowViewWorkOrder = ref(false);
const viewWorkOrderRef = ref();
const createWorkOrder = ref(null);
const formSearchRef = ref();
const searchTimeRange = ref();

const formSearchState = reactive({
  orderNo: '',
  faultOverview: '',
  type: type,
  status: '',
  projectIds:[],
  createTimeStart: '',
  finishTimeEnd: ''
});
let dataSource = ref([]);
let columns= [
  {
    title: '序号',
    dataIndex: 'index',
    width:80,
    customRender: (op) => {return ((pagination.value.current-1)*pagination.value.pageSize)+op.index+1}, //自定义渲染序号
  },{
    title: '工单号',
    dataIndex: 'orderNo',
    minWidth: 50
  },{
    title: '责任人',
    dataIndex: 'reportUser',
    minWidth:20,
    ellipsis: true
  },{
    title: '故障描述',
    dataIndex: 'faultOverview',
    ellipsis: true,
    minWidth: 100
  },{
    title: '状态',
    dataIndex: 'statusMap',
    width: 180
  },{
    title: '创建时间',
    dataIndex: 'createTime',
    width: 150,
  },{
    title: '完成时间',
    dataIndex: 'finishTime',
    width: 150,
  },{
    title: '评价',
    dataIndex: 'commentMap',
    width: 80,
  },{
    title: '操作',
    dataIndex: 'operation',
    fixed: 'right',
    width:180
  }
];

let pagination = ref({
  current: 1,     //当前页
  pageSize: 15,  //每页条数
  total: 1,     //数据总数
  pageSizeOptions:['2','4','6'],
  showSizeChanger:true
});

/**
 * 清空searchData 数据
 */
/*const clearSearchData = ()=>{
  formSearchState.orderNo = '';
  formSearchState.type = type;
  formSearchState.status = '';
  formSearchState.projectIds = [];
  searchCreateTimeRange.value = null;
  searchTimeRange.value = null;
  formSearchState.createTimeStart = '';
  formSearchState.createTimeEnd = '';
  formSearchState.finishTimeStart = '';
  formSearchState.finishTimeEnd = '';
}*/

onMounted(()=>{
  findAllItem(1);

})

/**
 * 查询所有
 * @param page 页数
 */
const findAllItem = function (page) {
  loading.value = true;
  let params = clone(formSearchState);  //克隆新的数据以免干扰formSearchState
  params.page = page;
  params.rows = pagination.value.pageSize;
  //searchTimeRange 时间范围查询是createTimeStart、finishTimeEnd两个时间并且是未完成的工单
  if (searchTimeRange.value) {
    params.createTimeStart = searchTimeRange.value[0].format("YYYY-MM-DD HH:mm:ss");  //将时间组件的日期格式转换成YYYY-MM-DD日期形式
    params.finishTimeEnd = searchTimeRange.value[1].format("YYYY-MM-DD HH:mm:ss");
  }
  //console.log("findAllItem searchData----",formSearchState);
  api_workOrder.findAllMyWorkOrder(params).then(res => {
    //console.log("res----",res);
    if (res?.data?.code === 0) {
      dataSource.value = [];
      const dataList = res.data.data.list;
      const _total = res.data.data.total;
      pagination.value.total = _total;
      //console.log("%%-->",1%5);
      dataList.forEach((item) => {
        //console.log("dataList-->",setFlowType(item.flowType));

        dataSource.value.push(item);
      })
    } else {
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
    loading.value = false;
  });
}

/**
 * 分页响应事件
 * @param page {@link pagination}
 */
let onPageChange = function (page){
  findAllItem(page);
}
/**
 * 改变pageSize分页条数
 * @param current 当前页
 * @param size  分页条数
 */
const onShowSizeChanger = function (current, size){
  pagination.value.current = current;
  pagination.value.pageSize = size;
}
/**
 * 跳到第一页
 */
const toHomePage = ()=>{
  findAllItem(1);
  pagination.value.current = 1;
}
/**
 * 跳到最后页
 */
/*const toEndPage = ()=>{
  findAllItem(getTotalPage());
  pagination.value.current = getTotalPage();
}*/
/**
 * 刷新当前页
 */
const toCurrentPage = ()=>{
  findAllItem(pagination.value.current);
}

/**
 * 获取当前总页数
 */
/*const getTotalPage =()=>{
  if (pagination.value.total === 0){
    return 1;
  }
  return pagination.value.total%pagination.value.pageSize === 0
      ? pagination.value.total/pagination.value.pageSize
      : parseInt(pagination.value.total/pagination.value.pageSize)+1;
}*/






/**
 * 搜索
 */
const onSearch = ()=>{
  formSearchRef.value.validateFields().then(()=>{
    //console.log("onSearch  values ---",values);

    toHomePage();
  }).catch(()=>{
    //console.log("onSearch  info ---",info);
  });
}

/**
 * 显示创建工单弹窗
 */
const showCreateWorkOrder = ()=>{
  createWorkOrder.value.showAddItemDialog();  //父调子方法
}
/**
 * 创建工单组件子调父回调
 * @param flg 创建工单成功or失败
 */
const callback_CreateWorkOrder = (flg)=>{
  if (flg){
    toHomePage();
  }
}

/**
 * 选择的项目回调的选择项的value值
 * @param val 选择项的value值
 */
const callback_selProject = (val)=>{
  formSearchState.projectIds = val;
}


/**
 * 查看工单
 * @param id 工单id
 */
const showViewWorkOrder = (id)=>{
  viewWorkOderId.value = id;
  isShowViewWorkOrder.value = true;
}
/**
 * 查看工单窗口关闭回调
 */
const callbackViewWorkOrder = ()=>{
  viewWorkOderId.value = null;
  isShowViewWorkOrder.value = false;
  toCurrentPage();
}


</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}

.block{
  box-sizing:border-box;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
