<template>
  <div ref="container" style="height:100%;position: relative;overflow-x:hidden;overflow-y: auto;">
    <div style="">
      <div class="tableHeaderContainer" >
        <div style="flex: 1;display: flex;align-items: center;padding-left: 10px;">
          功能操作
          <!-- 搜索抽屉框(局部弹窗需要外层div Css position: relative;overflow: hidden ) start-->
          <a-drawer
              title="条件筛选"
              :mask="true"
              placement="top"
              v-model:visible="searchDrawerVisible"
              :get-container="()=>{return $refs.container}"
              :style="{ position: 'absolute' }"
              :height="200"
          >
            <a-form ref="formSearchRef" layout="inline" :model="formSearchState" name="search">
              <a-form-item label="用户名" name="name">
                <a-input v-model:value="formSearchState.name" size="default" />
              </a-form-item>
              <a-form-item label="手机号" name="phone">
                <a-input v-model:value="formSearchState.phone" size="default" />
              </a-form-item>
            </a-form>
            <template #footer>
              <div style="text-align: right">
                <a-button style="margin-right: 8px" @click="()=>{searchDrawerVisible = false;}"><template #icon><CloseOutlined /></template>关闭</a-button>
                <a-button type="primary" @click="onSearch()"><template #icon><SearchOutlined /></template>搜索</a-button>
              </div>
            </template>
          </a-drawer>
          <!-- 搜索抽屉框 end -->
        </div>
        <div style="flex: 0;display: flex;justify-content: flex-end;align-items: center;padding-right: 10px;">
          <a-button style="margin-left: 5px;" type="primary" danger size="small" @click="listUserOffJobDialogRef.show()"><template #icon><DisconnectOutlined /></template>离职用户<span v-if="userOffJobCount && userOffJobCount > 0" style="margin-left: 6px;">- {{userOffJobCount}}人</span></a-button>
          <a-button style="margin-left: 5px;" type="primary" size="small" @click="()=>{searchDrawerVisible = true;}"><template #icon><SearchOutlined /></template>条件筛选</a-button>
          <a-button v-permission="'user_addUser'" style="margin-left: 5px;" type="primary" size="small" @click="showAddItemDialog()"><template #icon><UserAddOutlined /></template>添加用户</a-button>
        </div>
      </div>
      <!-- :scroll 设置y值高度可实现表头固定滚动，整个body100vh - 头部header高度90 - FrameMain=>content Margin 5px - tableHeaderContainer 40 - 表头高度40 - 分页高度52 -->
      <div style="display: flex;align-items: flex-start;justify-content: space-between;">
        <div style="width: 250px;min-width:250px;background-color: #ffffff;margin-right:8px;overflow-y: auto">
          <a-tree
              v-model:expandedKeys="expandedDeptKeys"
              v-model:selectedKeys="selectedDeptKeys"
              show-line
              :tree-data="treeAllDeptDataMain"
              @select="onTreeDeptSel"
          >
            <template #switcherIcon="{ switcherCls }"><down-outlined :class="switcherCls" /></template>
          </a-tree>
        </div>
        <div style="flex: 1;width:100%;background-color: #ffffff;">
          <a-table
              class="ant-table-striped"
              :columns="columns"
              :data-source="dataSource"
              :pagination="false"
              :row-class-name="setRowClass"
              :bordered="true"
              size="small"
              :loading="loading"
              :scroll="{ x: 1000,  y: 'calc(100vh - 227px)' }"
          >
            <template #bodyCell="{ column,record }">
              <template v-if="column.dataIndex === 'operation'">
                <div style="display: flex;justify-content: left;">
                  <a-button v-permission="'user_editUser'" style="margin: 0px 2px;" type="link" size="small" @click="showEditItemDialog(record)"><template #icon><EditOutlined /></template><span style="margin-left:3px;">编辑</span></a-button>
                  <a-button v-permission="'user_allotRole'" v-if="record.type !== Constant.USER_TYPE_ADMIN" style="margin: 0px 2px;" type="link" size="small" @click="showAllotDialog(record)"><template #icon><ProfileOutlined /></template><span style="margin-left:3px;">角色</span></a-button>
                  <a-button  v-if="record.type !== Constant.USER_TYPE_ADMIN" style="margin: 0px 2px;" type="link" size="small" danger @click="setUserOffJob(record.id)"><template #icon><DisconnectOutlined /></template><span style="margin-left:3px;">离职</span></a-button>
                  <a-button  v-if="record.type !== Constant.USER_TYPE_ADMIN" style="margin: 0px 2px;" type="link" size="small" danger @click="removeItem(record.id)"><template #icon><ApartmentOutlined /></template><span style="margin-left:3px;">设为访客</span></a-button>
                  <a-button v-permission="'forceUpdatePwd'" v-if="record.type !== Constant.USER_TYPE_ADMIN" style="margin: 0px 2px;" type="link" size="small" danger @click="showForcUpdatePwdDialog(record)"><template #icon><KeyOutlined /></template><span style="margin-left:3px;">修改密码</span></a-button>
                </div>
              </template>
              <template v-else-if="column.dataIndex === 'role'">
                <template v-if="record.type === Constant.USER_TYPE_ADMIN">管理员</template>
                <template v-for="role in record.roleList" :key="role.id">
                  <a-tag>{{role.name}}</a-tag>
                </template>
              </template>
              <template v-else-if="column.dataIndex === 'property'">
                <a-tooltip title="工单处理人" v-if="record.isReceiver === Constant.USER_PROPERTY_ISRECEIVER"><a-tag  color="cyan">处理人</a-tag></a-tooltip>
                <a-tooltip title="工单指派人" v-if="record.isAssign === Constant.USER_PROPERTY_ISASSIGN_YES"><a-tag  color="cyan">指派人</a-tag></a-tooltip>
                <a-tooltip :title="'企业微信ID：'+record.wecomId" v-if="record.wecomId"><wechat-filled style="margin: 0px 5px;color: #79d951;"/></a-tooltip>
                <a-tooltip :title="'电子邮箱：'+record.email" v-if="record.email"><mail-filled style="margin: 0px 5px;color: #deb273;"/></a-tooltip>
              </template>
            </template>
          </a-table>

          <div style="padding:10px;text-align: center">
            <a-pagination v-model:current="pagination.current" :total="pagination.total" :page-size="pagination.pageSize"
                          @change="onPageChange" @showSizeChange="onShowSizeChanger" :show-total="total => `共 ${total} 条`"
                          :page-size-options="['10','20','30','40','50']" show-size-changer>
              <template #buildOptionText="props">
                <span>{{ props.value }}条/页</span>
              </template>
            </a-pagination>
          </div>
        </div>
      </div>

      <!--    编辑用户弹窗     -->
      <a-modal
          v-model:visible="editItem_dialog_visible"
          title="编辑用户"
          ok-text="确认"
          cancel-text="取消"
          @ok="onUpdateItemOK"
          :confirmLoading="false"
          :destroy-on-close="true"
        >
        <a-spin :spinning="loading">
        <a-form ref="formEditRef" :model="formEditState" :label-col="{ span: 6 }" :wrapper-col="{ span: 18}" name="edit">
          <a-form-item
              label="所属部门"
              name="deptId"
              :rules="[{ required: true, message: '请选择所属部门' ,trigger:'blur'}]">
            <a-tree-select
                v-model:value="formEditState.deptId"
                tree-data-simple-mode
                tree-default-expandAll
                show-search
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                placeholder="请选择"
                allow-clear
                :tree-data="treeAllDeptData"
            >
            </a-tree-select>
          </a-form-item>
          <a-form-item
              label="用户名"
              name="name"
              :rules="[{ required: true, message: '请输入用户名',trigger:'blur' },{pattern:/^[\u4e00-\u9fa5\w]{1,10}$/,message:'用户名只能包含中文、英文、数字及下划线最大长度1~10位',trigger:'blur'}]"
            >
            <a-input v-model:value="formEditState.name" size="default" />
          </a-form-item>
          <a-form-item
              label="手机号"
              name="phone"
              :rules="[{ required: true, message: '请输入手机号' },{pattern:/^1[0-9]{10}$/,message:'手机号不符合规则',trigger:'blur'}]"
          >
            <a-input v-model:value="formEditState.phone" size="default" />
          </a-form-item>
          <a-form-item
              label="工作地址"
              name="addr"
              :rules="[{ required: false},{max:100,message:'工作地址字符长度超限',trigger:'blur'}]"
          >
            <a-input v-model:value="formEditState.addr" size="default" :maxlength="100"/>
          </a-form-item>

          <a-form-item
              label="电子邮箱"
              name="email"
              :rules="[{ required: false },{type:'email',message:'电子邮箱格式错误',trigger:'blur'}]"
          >
            <a-input v-model:value="formEditState.email" size="default" />
          </a-form-item>
          <a-form-item
              label="企业微信ID"
              name="wecomId"
              :rules="[{ required: false},{max:100,message:'企业微信ID字符长度超限',trigger:'blur'}]"
          >
            <a-input v-model:value="formEditState.wecomId" size="default" :maxlength="100"/>
          </a-form-item>

          <a-form-item
              label="工单处理人"
              name="isReceiver"
              :rules="[{ required: true, message: '请设置工单处理人',trigger:'blur' }]"
          >
            <a-radio-group v-model:value="formEditState.isReceiver" @change="onChangeEditReceiver">
              <a-radio value="0">否</a-radio>
              <a-radio value="1">是</a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item
              v-if="formEditState.isReceiver === Constant.USER_PROPERTY_ISRECEIVER+''"
              label="工单指派人"
              name="isAssign"
              :rules="[{ required: true, message: '请设置工单指派人',trigger:'blur' }]"
          >
            <a-radio-group v-model:value="formEditState.isAssign">
              <a-radio value="0">否</a-radio>
              <a-radio value="1">是</a-radio>
            </a-radio-group>
          </a-form-item>

        </a-form>
        </a-spin>
      </a-modal>

      <!--    添加用户弹窗     -->
      <a-modal
          v-model:visible="addItem_dialog_visible"
          title="添加用户"
          ok-text="确认"
          cancel-text="取消"
          @ok="onSaveItemOK"
          :confirmLoading="false"
          :destroy-on-close="true"
          width="550px"
      >
        <a-spin :spinning="loading">
          <a-form ref="formAddRef" :model="formAddState" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" name="add">
            <a-form-item
                label="所属部门"
                name="deptId"
                :rules="[{ required: true, message: '请选择所属部门' ,trigger:'blur'}]">
              <a-tree-select
                  v-model:value="formAddState.deptId"
                  tree-data-simple-mode
                  tree-default-expandAll
                  show-search
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择"
                  allow-clear
                  :tree-data="treeAllDeptData"
              >
              </a-tree-select>
            </a-form-item>
            <a-form-item
                label="手机号"
                name="phone"
                :rules="[{ required: true, message: '请输入手机号' },{pattern:/^1[0-9]{10}$/,message:'手机号不符合规则',trigger:'blur'}]"
              >
              <a-input v-model:value="formAddState.phone" size="default" />
            </a-form-item>
            <a-form-item
                label="用户名"
                name="name"
                :rules="[{ required: true, message: '请输入用户名',trigger:'blur' },{pattern:/^[\u4e00-\u9fa5\w]{1,10}$/,message:'用户名只能包含中文、英文、数字及下划线最大长度1~10位',trigger:'blur'}]"
              >
              <a-input v-model:value="formAddState.name" size="default" />
            </a-form-item>

            <a-form-item
                label="密码"
                name="pwd"
                :rules="[{ required: !isDefaultPwd, message: '请输入密码',trigger:'blur' },{pattern:/^(\w){6,11}$/,message:'密码只能以大小写字母、数字和下划线组合长度6~11位',trigger:'blur'}]"
              >
              <a-input-password v-model:value="formAddState.pwd" size="default"  :disabled="isDefaultPwd"/>
              <a-form-item-rest><a-checkbox v-model:checked="isDefaultPwd" @change="()=>{isDefaultPwd ? formAddState.pwd=defaultPwd : formAddState.pwd='' ;}">系统默认密码：123456</a-checkbox></a-form-item-rest>
            </a-form-item>
            <a-form-item
                label="工作地址"
                name="addr"
                :rules="[{ required: false},{max:100,message:'工作地址字符长度超限',trigger:'blur'}]"
            >
              <a-input v-model:value="formAddState.addr" size="default" :maxlength="100"/>
            </a-form-item>

            <a-form-item
                label="电子邮箱"
                name="email"
                :rules="[{ required: false },{type:'email',message:'电子邮箱格式错误',trigger:'blur'}]"
            >
              <a-input v-model:value="formAddState.email" size="default" />
            </a-form-item>
            <a-form-item
                label="企业微信ID"
                name="wecomId"
                :rules="[{ required: false},{max:100,message:'企业微信ID字符长度超限',trigger:'blur'}]"
            >
              <a-input v-model:value="formAddState.wecomId" size="default" :maxlength="100"/>
            </a-form-item>

            <a-form-item
                label="工单处理人"
                name="isReceiver"
                :rules="[{ required: true, message: '请设置工单处理人',trigger:'blur' }]"
            >
              <a-radio-group v-model:value="formAddState.isReceiver" @change="onChangeAddReceiver">
                <a-radio value="0">否</a-radio>
                <a-radio value="1">是</a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item
                v-if="formAddState.isReceiver === Constant.USER_PROPERTY_ISRECEIVER+''"
                label="工单指派人"
                name="isAssign"
                :rules="[{ required: true, message: '请设置工单指派人',trigger:'blur' }]"
            >
              <a-radio-group v-model:value="formAddState.isAssign">
                <a-radio value="0">否</a-radio>
                <a-radio value="1">是</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>

      <!--    分配角色弹窗     -->
      <a-modal
          v-model:visible="allot_dialog_visible"
          ok-text="确认"
          cancel-text="取消"
          @ok="onCheckedItemOK"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <template #title>
          <div>分配角色<a-tooltip color="orange" title="注意：变更角色选择将会影响用户的权限选择"><ExclamationCircleOutlined style="color: orange;margin-left: 5px"/></a-tooltip></div>
          <div v-if="!isShowNullDataMsg" style="color: #b6b6b6;font-size: 12px;margin-top: 5px;">注意：变更角色选择将会影响用户的权限选择</div>
        </template>

        <a-spin :spinning="loading">
          <a-alert v-if="isShowNullDataMsg" message="没有角色选择，请先到角色管理中创建角色。" banner />

          <a-tree
              v-model:expandedKeys="expandedKeys"
              v-model:selectedKeys="selectedKeys"
              v-model:checkedKeys="checkedKeys"
              checkable
              :tree-data="userRoleTreeData"
              :height="500"
              :auto-expand-parent="true"
          >
          </a-tree>
        </a-spin>
      </a-modal>

      <!--    强制修改密码弹窗     -->
      <a-modal
          v-model:visible="forceUpdatePwd_dialog_visible"
          ok-text="确认"
          cancel-text="取消"
          @ok="onforceUpdatePwdItemOK"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <template #title>
          <div>强制修改用户密码<a-tooltip color="orange" title="注意：为了安全起见需要验证你的密码"><ExclamationCircleOutlined style="color: orange;margin-left: 5px"/></a-tooltip></div>
        </template>

        <a-spin :spinning="loading">
          <a-alert message="注意：为了安全起见需要验证你的密码。" banner />

          <a-form ref="formForceUpdatePwdRef" :model="formForcePwdState" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
            <a-form-item label="你的密码" name="curUserPwd" :rules="[{ required: true, message: '请输入你的密码' },{pattern:/^(\w){6,11}$/,message:'你的密码只能以大小写字母、数字和下划线组合长度6~11位',trigger:'blur'}]">
              <a-input-password v-model:value="formForcePwdState.curUserPwd" size="default" :maxlength="11">
                <template #prefix>
                  <LockOutlined class="site-form-item-icon" />
                </template>
              </a-input-password>
            </a-form-item>

            <a-form-item label="用户密码" name="pwd" :rules="[{ required: true, message: '请输入用户密码' },{pattern:/^(\w){6,11}$/,message:'用户密码只能以大小写字母、数字和下划线组合长度6~11位',trigger:'blur'}]">
              <a-input-password v-model:value="formForcePwdState.pwd" size="large" :maxlength="11">
                <template #prefix>
                  <LockOutlined class="site-form-item-icon" />
                </template>
              </a-input-password>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>

    </div>

    <ListUserOffJobDialog ref="listUserOffJobDialogRef" @callback="callbackSetUserOnJob"/>

  </div>
</template>

<script setup>
import {onMounted, ref, createVNode, reactive, defineAsyncComponent} from "vue";
import api_user from "@/framework/api/user";
import api_dept from "@/api/dept";
import {message,Modal} from "ant-design-vue";
import {useDirectStore} from "@/store/directStore";
import {
  SearchOutlined,
  UserAddOutlined,
  EditOutlined,
  CloseOutlined,
  ProfileOutlined,
  ExclamationCircleOutlined,
  DownOutlined,
  WechatFilled,
  MailFilled, LockOutlined,KeyOutlined,ApartmentOutlined,DisconnectOutlined
} from "@ant-design/icons-vue";
import api_auth from "@/api/auth";
import {Constant} from "@/js/common";

const ListUserOffJobDialog = defineAsyncComponent(() =>{
  return import('@/components/_listUserOffJobDialog.vue')
})

    const loading = ref(false);
    const formAddRef = ref();
    const formEditRef = ref();
    const formSearchRef = ref();
    const listUserOffJobDialogRef = ref();
    const formForceUpdatePwdRef = ref();
    const searchDrawerVisible = ref(false);
    const editItem_dialog_visible = ref(false);
    const addItem_dialog_visible = ref(false);
    const forceUpdatePwd_dialog_visible = ref(false);
    const isDefaultPwd = ref(true);   //是否勾选使用系统默认密码(同时失能密码输入和校验功能)
    const defaultPwd = "123456";  //默认密码
    const allot_dialog_visible = ref(false);
    const expandedKeys = ref([]);
    const selectedKeys = ref([]);
    const checkedKeys = ref([]);
    let userRoleTreeData = ref([]);
    let isShowNullDataMsg = ref(false);
    let curSelUserId = "";
    const userOffJobCount = ref("");
    const directStore = useDirectStore();
    const directDtaList = directStore.getDataList();
    const formAddState = reactive({
      name: '',
      phone: '',
      pwd: defaultPwd,
      email:'',
      wecomId:'',
      addr:'',
      isReceiver: '0',
      isAssign: '0',
      deptId: '',
    });

    const formEditState = reactive({
      id: '',
      name: '',
      phone: '',
      email:'',
      wecomId:'',
      addr:'',
      isReceiver: '0',
      isAssign: '0',
      deptId: '',
    });
    const formForcePwdState = reactive({
      id: '',
      curUserPwd: '',
      pwd: '',
    });
    const formSearchState = reactive({
      name: "",
      phone: "",
      isReceiver: '',
      deptId: ""
    });
    let dataSource = ref([]);
    let columns= [
      {
        title: '序号',
        dataIndex: 'index',
        width:50,
        customRender: (op) => {return ((pagination.value.current-1)*pagination.value.pageSize)+op.index+1}, //自定义渲染序号
      },{
        title: '用户名',
        dataIndex: 'name',
        width:100
      },{
        title: '手机号',
        dataIndex: 'phone',
      },{
        title: '部门',
        dataIndex: 'dept_name',
      },{
        title: '角色',
        dataIndex: 'role',
      },{
        title: '属性',
        dataIndex: 'property',
      },{
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width:410
      }
    ];

    const expandedDeptKeys = ref([]);
    const selectedDeptKeys = ref([]);
    let treeAllDeptDataMain = ref([]);
    let treeAllDeptData = ref([]);

    let pagination = ref({
      current: 1,     //当前页
      pageSize: 10,  //每页条数
      total: 1,     //数据总数
      pageSizeOptions:['2','4','6'],
      showSizeChanger:true
    });
    /**
     * 清空searchData 数据
     */
    const clearSearchData = ()=>{
      formSearchState.name = "";
      formSearchState.phone = "";
      formSearchState.deptId = "";
    }
    /**
     * 查询所有用户
     * @param page 页数
     */
    const findAllUser = function (page){
      loading.value = true;
      formSearchState.page = page;
      formSearchState.rows = pagination.value.pageSize;
      //console.log("findAllUser searchData----",formSearchState);
      api_user.userManage(formSearchState).then(res=>{
        //console.log("res----",res);
        if(res?.data?.code === 0){
          dataSource.value = [];
          const userList = res.data.data.userList;
          const _total = res.data.data.total;
          pagination.value.total = _total;

          userList.forEach((user)=>{
            //console.log("user-->",user);
            dataSource.value.push({id:user.id,name:user.name,phone:user.phone,type:user.type,isReceiver:user.isReceiver,
              wecomId:user.wecomId,email:user.email,addr:user.addr,isAssign:user.isAssign,dept_name:user.dept.name,roleList:user.roleList})
          })
        }else {
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loading.value = false;
      });

    }
    /**
     * 查询所有部门
     */
    const findAllDept = function (){
      loading.value = true;
      //console.log("findAllItem searchData----",formSearchState);
      api_dept.findAllDept({}).then(res=>{
        //console.log("res----",res);
        if(res?.data?.code === 0){
          const list = res.data.data.list;
          if (list && list.length > 0){
            const newList = convert(list);
            treeAllDeptDataMain.value = newList;
            //展开所有层级
            for (let e of list){
              expandedDeptKeys.value.push(e.id);
            }
          }
          if (list && list.length > 0){
            for (let item of list){
              treeAllDeptData.value.push({id:item.id,pId:item.parentId,value:item.id,title:item.name,})
            }
          }
        }else {
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loading.value = false;
      });

    }
    /**
     * 分页响应事件
     * @param page {@link pagination}
     */
    let onPageChange = function (page){
      findAllUser(page);
    }
    /**
     * 改变pageSize分页条数
     * @param current 当前页
     * @param size  分页条数
     */
    const onShowSizeChanger = function (current, size){
      pagination.value.current = current;
      pagination.value.pageSize = size;
    }
    /**
     * 跳到第一页
     */
    const toHomePage = ()=>{
      findAllUser(1);
      pagination.value.current = 1;
      countUserOffJob();
    }
    /**
     * 跳到最后页
     */
    const toEndPage = ()=>{
      findAllUser(getTotalPage());
      pagination.value.current = getTotalPage();
    }
    /**
     * 刷新当前页
     */
    const toCurrentPage = ()=>{
      findAllUser(pagination.value.current);
      countUserOffJob();
    }

    /**
     * 获取当前总页数
     */
    const getTotalPage =()=>{
      if (pagination.value.total === 0){
        return 1;
      }
      return pagination.value.total%pagination.value.pageSize === 0
          ? pagination.value.total/pagination.value.pageSize
          : parseInt(pagination.value.total/pagination.value.pageSize)+1;
    }

    onMounted(()=>{
      findAllUser(1);
      findAllDept();
      countUserOffJob();
    })

    /**
     * 统计组织内所有已离职的人数
     */
    const countUserOffJob = ()=>{
      api_user.countUserOffJob().then(res=>{
        if(res?.data?.code === 0){
          userOffJobCount.value = res.data.data;
        }
      });
    }
    /**
     * 显示修改记录弹窗
     * @param record 实体数据
     */
    const showEditItemDialog = record =>{
      editItem_dialog_visible.value = true;
      loading.value = true;
      api_user.getUser(record.id).then(res=>{
        if(res?.data?.code === 0){
          formEditState.id = res.data.data.id;
          formEditState.name = res.data.data.name;
          formEditState.phone = res.data.data.phone;
          formEditState.addr = res.data.data.addr;
          formEditState.email = res.data.data.email;
          formEditState.wecomId = res.data.data.wecomId;
          formEditState.isReceiver = res.data.data.isReceiver.toString();
          formEditState.isAssign = res.data.data.isAssign.toString();
          formEditState.deptId = res.data.data.deptId;
        }else{
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loading.value = false;
      });
    }
    /**
     * 点击修改记录弹窗->确认按钮
     */
    const onUpdateItemOK = function (){
      formEditRef.value.validateFields().then(values=>{
        loading.value = true;
        values.id = formEditState.id;
        values.isAssign = formEditState.isAssign;
        //console.log("onUpdateItemOK  values ---",values);
        api_user.updateUser(values).then(res=>{
          if(res?.data?.code === 0){
            message.success("操作成功",3);
            editItem_dialog_visible.value = false;
            toCurrentPage();
          }else{
            if (res?.data?.msg){
              message.error(res?.data?.msg+" "+res?.data?.data,5);
            }
          }
          loading.value = false;
        })
      }).catch(()=>{
        //console.log("onUpdateItemOK  info ---",info);
      });
    }
    /**
     * 显示添加用户弹窗
     */
    const showAddItemDialog = () =>{
      addItem_dialog_visible.value = true;
    }
    /**
     * 添加用户弹窗->确认
     */
    const onSaveItemOK = () =>{
      formAddRef.value.validateFields().then(values=>{
        loading.value = true;
        values.isAssign = formAddState.isAssign;
        //console.log("onSaveItemOK  values ---",values);
        api_user.saveUser(values).then(res=>{
          if(res?.data?.code === 0){
            message.success("操作成功",3);
            addItem_dialog_visible.value = false;
            clearSearchData();  //清空searchData,避免当前列表处于搜索后的数据显示，添加新的数据后不显示
            toEndPage();
            selectedDeptKeys.value = [];  //同时取消选择的部门
          }else{
            if (res?.data?.msg){
              message.error(res?.data?.msg+" "+res?.data?.data,5);
            }
          }
          loading.value = false;
        })
      }).catch(()=>{
        //console.log("onSaveItemOK  info ---",info);
      });
    }
    /**
     * 显示强制修改用户密码弹窗
     * @param record record
     */
    const showForcUpdatePwdDialog = (record)=>{
      forceUpdatePwd_dialog_visible.value = true;
      curSelUserId = record.id;
    }
    /**
     * 强制修改用户密码（管理员及授权操作的）,除管理员自己不可修改管理员的密码
     * @param Vo{curUserPwd:当前登录用户的密码,id:要修改的用户id,pwd:密码}
     */
    const onforceUpdatePwdItemOK = ()=>{
      formForceUpdatePwdRef.value.validateFields().then(values=>{
        loading.value = true;
        values.id = curSelUserId;
        api_user.forceUpdateUserPwd(values).then(res=>{
          if(res?.data?.code === 0){
            message.success("用户密码修改成功",3);
            forceUpdatePwd_dialog_visible.value = false;
          }else {
            if (res?.data?.msg){
              message.error(res?.data?.msg+" "+res?.data?.data,5);
            }

          }
          loading.value = false;
        });
      }).catch(()=>{
        //console.log("onSaveItemOK  info ---",info);
      });
    }

    /**
     * 移除用户（将用户降为访客类型）
     * @param uid uid
     */
    const setUserOffJob = function (uid){
      //console.log(record);
      Modal.confirm({
        title: '系统提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: '此操作将会使用户变更为离职状态，变更后此用户将不可再登陆到系统，您确定要操作吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          return new Promise((resolve,reject)=>{  //使用异步处理同时按钮显示加载动画
            api_user.setUserOffJob(uid).then(res=>{
              if(res?.data?.code === 0){
                message.success("操作成功",3);
                resolve();
                toCurrentPage();
              }else{
                if (res?.data?.msg){
                  message.error(res?.data?.msg+" "+res?.data?.data,5);
                }
                reject();
              }
            });
          });
        },
        onCancel() {
        }
      });
    }

    /**
     * 移除用户（将用户降为访客类型）
     * @param uid uid
     */
    const removeItem = function (uid){
      //console.log(record);
      Modal.confirm({
        title: '系统提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: '此操作将会使用户变更为访客类型同时转移到【访客管理】中并放弃所有已有功能权限，您确定要操作吗?',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          return new Promise((resolve,reject)=>{  //使用异步处理同时按钮显示加载动画
            api_user.removeToVisitor(uid).then(res=>{
              if(res?.data?.code === 0){
                message.success("操作成功",3);
                resolve();
                toCurrentPage();
              }else{
                if (res?.data?.msg){
                  message.error(res?.data?.msg+" "+res?.data?.data,5);
                }
                reject();
              }
            });
          });
        },
        onCancel() {
        }
      });
    }


    /**
     * 搜索
     */
    const onSearch = ()=>{
      formSearchRef.value.validateFields().then(()=>{
        searchDrawerVisible.value = false;
        //console.log("onSearch  values ---",values);

        toHomePage();
      }).catch(()=>{
        //console.log("onSearch  info ---",info);
      });
    }
    /**
     * 显示分配角色弹窗
     * @param record
     */
    const showAllotDialog = record=>{
      loading.value = true;
      //console.log(record);
      curSelUserId = record.id;
      allot_dialog_visible.value = true;
      isShowNullDataMsg.value = false;
      expandedKeys.value = [];
      checkedKeys.value = [];
      selectedKeys.value = [];
      userRoleTreeData.value = [];
      api_auth.showAllotRole(curSelUserId).then(res=>{
        if(res?.data?.code === 0){
          const dataList = res.data.data;
          if (dataList?.length > 0){
            dataList.forEach((role)=>{
              userRoleTreeData.value.push({title:role.name,key:role.id});
              if (role.checked === true){
                checkedKeys.value.push(role.id);
              }
            });
          }else{
            isShowNullDataMsg.value = true;
          }
        }else{
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loading.value = false;
      });
    }
    /**
     * 处理选择角色的操作
     */
    const onCheckedItemOK = ()=>{
      loading.value = true;
      let data = {userId:curSelUserId,roleIds:checkedKeys.value};
      api_auth.doAllotUserRole(data).then(res=>{
        if(res?.data?.code === 0){
          message.success("操作成功",3);
          allot_dialog_visible.value = false;
          toCurrentPage();
        }else{
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loading.value = false;
      })
    }
    /**
     * 部门树节点选择事件
     * @param key id
     */
    const onTreeDeptSel = (key)=>{
      if (key){
        clearSearchData();
        formSearchState.deptId = key[0];
        findAllUser(1);
      }
    }
    /**
     * 编辑用户设置选择是否为处理人change事件
     */
    const onChangeEditReceiver = ()=>{
      if (formEditState.isReceiver === '0'){
        formEditState.isAssign = '0';
      }
    }
    /**
     * 添加用户设置选择是否为处理人change事件
     */
    const onChangeAddReceiver = ()=>{
      if (formAddState.isReceiver === '0'){
        formAddState.isAssign = '0';
      }
    }

    /**
      * 离职用户窗口中-恢复在职操作回调
    */
    const callbackSetUserOnJob = ()=>{
      toHomePage();
    }

    /**
     * 转换成<a-tree>树型控件所需的data格式
     * @param rows 对象数组
     * @returns {*[]}
     */
    const convert = function(rows){
      function exists(rows, parentId){
        for(let i=0; i<rows.length; i++){
          if (rows[i].id == parentId) return true;
        }
        return false;
      }

      let nodes = [];
      // get the top level nodes
      for(let i=0; i<rows.length; i++){
        let row1 = rows[i];
        if (!exists(rows, row1.parentId)){
          nodes.push({
            key:row1.id,      //<a-tree>树型字段
            id:row1.id,
            title:row1.name,  //<a-tree>树型字段，输入框控件所需的data格式所需的属性，使用在添加和编辑中选择所属节点下拉树
            value:row1.id     //<a-tree-select>树型字段
          });

        }
      }

      let toDo = [];
      for(let i=0; i<nodes.length; i++){
        toDo.push(nodes[i]);
      }

      while(toDo.length){
        let node = toDo.shift();    // the parent node
        // get the children nodes
        for(let i=0; i<rows.length; i++){
          let row = rows[i];
          if (row.parentId == node.key){
            let child = {key:row.id,id:row.id,title:row.name,value:row.id};
            if (node.children){
              node.children.push(child);
            } else {
              node.children = [child];
            }

            toDo.push(child);

          }
        }
      }
      return nodes;
    }
    /**
     * 设置表格行样式（角色删除时有用户关联指引此处）
     * <br/> 在<style>中不能有scope 属性
     * @param record 实体记录
     * @param index 索引
     * @returns {string|string|null}
     */
    const setRowClass = (record,index)=>{
      let isDirect = false;
      if (directDtaList && directDtaList.length > 0){
        directDtaList.forEach((userId)=>{
          if (record.id === userId){
            isDirect = true;
            return false;
          }
        });
      }
      if (isDirect){
        return 'tableSelRowStyle';
      }
      return (index % 2 === 1 ? 'tableSelRowDefaultStyle' : null);
    }





</script>

<style>
  .ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
  }
  .tableHeaderContainer{
    display: flex;
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #fafafa;
    background-color: #ffffff;
  }
  /* 默认的行斑马纹颜色 */
  .tableSelRowDefaultStyle{
    background-color: #fafafa;
  }
  /* 默认的行斑马纹颜色此行操作列的背景颜色 */
  .tableSelRowDefaultStyle .ant-table-cell-fix-right{
    background-color: #fafafa;
  }
  /* 指引选择的颜色 */
  .tableSelRowStyle{
    background-color: #ffe4b2;
  }
  /*取消表格操作列默认白色*/
  .tableSelRowStyle .ant-table-cell-fix-right{
    background: #ffe4b2;
  }
  /*隐藏部门树item前的默认图标*/
  .ant-tree-switcher-noop {
    display: none!important;
  }
  /*部门树item自定义padding距离*/
  .ant-tree-node-content-wrapper-normal {
    padding-left: 28px !important;
  }
</style>
