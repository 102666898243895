<template>
  <div ref="container" style="height:100%;position: relative;overflow: hidden">
    <div style="background-color: #fff;padding: 0px;">

      <div style="display: flex;align-items: center;">

        <div style="padding: 20px 15px;flex: 1;">
          <a-form ref="formSearchRef"  :model="formSearchState" name="search">
            <div >
              <a-space :size="10">
                <a-form-item name="orderNo">
                  <a-input v-model:value="formSearchState.orderNo" allowClear placeholder="工单号" size="default" />
                </a-form-item>
                <a-form-item name="faultOverview">
                  <a-input v-model:value="formSearchState.faultOverview" allowClear placeholder="故障描述" size="default" />
                </a-form-item>
                <a-form-item name="searchCreateTimeRange" >
                  <a-range-picker show-time v-model:value="searchTimeRange" format="YYYY-MM-DD HH:mm:ss" :locale="locale"  size="default"/>
                </a-form-item>
                <a-form-item>
                  <a-button type="primary" @click="onSearch()" size="default" style="margin-left: 0px;"><template #icon><SearchOutlined /></template>搜索</a-button>
                </a-form-item>
              </a-space>
            </div>

          </a-form>
        </div>

        <div style="padding: 0 10px;">
          <a-space :size="10">
            <a-button   style="margin-left: 5px;" type="primary"  size="default" @click="exportToExcelOfWorkOrderList"><template #icon><file-excel-outlined /></template>导出到Excel</a-button>
          </a-space>
        </div>

      </div>




      <!-- :scroll 设置y值高度可实现表头固定滚动，整个body100vh - 头部header高度90 - FrameMain=>content Margin 5px - tableHeaderContainer 72 - 表头高度40 - 分页高度52 -->
      <div style="padding: 0px 10px">
        <a-table
            class="ant-table-striped"
            :columns="columns"
            :data-source="dataSource"
            :pagination="false"
            :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
            :bordered="true"
            size="small"
            :loading="loading"
            :scroll="{ x: 1200, y: 'calc(100vh - 259px)' }"
        >
          <template #bodyCell="{ column,record }">
            <template v-if="column.dataIndex === 'operation'">
              <div style="display: flex;justify-content: left;">
                <a-button  style="margin: 0px 4px;" type="link" size="small" @click="showViewWorkOrder(record.id)"><template #icon><eye-outlined /></template><span style="margin-left:3px;">查看</span></a-button>
              </div>
            </template>
            <template v-if="column.dataIndex === 'faultOverview'">
              <a-tooltip :title="record.faultOverview" placement="topLeft">{{record.faultOverview}}</a-tooltip>
            </template>
            <template v-else-if="column.dataIndex === 'reportUser'">
              {{record.reportUser?.name ? record.reportUser?.name : record.reportUserName}}
            </template>
            <template v-else-if="column.dataIndex === 'statusMap'">
              <!--            <div :style="'width:' + record.flowType.minSize*20 +'%'">
                            <template v-for="(items,index) in record.flowType.list" :key="index">
                              <a-steps size="small">
                                <template v-for="(item,index1) in items" :key="index1">
                                  <a-step status="finish" :title="item"/>
                                </template>
                              </a-steps>
                            </template>
                          </div>-->

              <a-badge status="success" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_FINISHED" text='已完成' />
              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_REPORT" :text='flowTypeObj[record.statusMap.code]' />
              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_DISPATCH" :text='flowTypeObj[record.statusMap.code]'/>
              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_ASSIGN" :text='flowTypeObj[record.statusMap.code]'/>
              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_REJECT" :text='flowTypeObj[record.statusMap.code]'/>
              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_RECEIVE" :text='flowTypeObj[record.statusMap.code]'/>
              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_ARRIVED" :text='flowTypeObj[record.statusMap.code]'/>
              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_TRANSFER" :text='flowTypeObj[record.statusMap.code]'/>
              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_DELAY" :text='flowTypeObj[record.statusMap.code]'/>
              <a-badge status="processing" v-if="record.statusMap.code === Constant.WORK_ORDER_FLOW_TYPE_COOPERATE" :text='flowTypeObj[record.statusMap.code]'/>
            </template>
            <template v-else-if="column.dataIndex === 'commentMap'">
              {{record.commentMap.value}}
            </template>
          </template>
        </a-table>
      </div>

      <div style="padding:10px;text-align: center">
        <a-pagination v-model:current="pagination.current" :total="pagination.total" :page-size="pagination.pageSize"
                      @change="onPageChange" @showSizeChange="onShowSizeChanger" :show-total="total => `共 ${total} 条`"
                      :page-size-options="['10','20','30','40','50']" show-size-changer>
          <template #buildOptionText="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>

      <!--    工单查看     -->
      <ViewWorkOrder ref="viewWorkOrderRef" :workOrderId="viewWorkOderId" @callbackViewWorkOrder="callbackViewWorkOrder" v-if="isShowViewWorkOrder"></ViewWorkOrder>

    </div>
  </div>
</template>

<script>
import {onMounted, ref, reactive, defineAsyncComponent,} from "vue";
import api_workOrder from "@/api/workOrder";
import {message} from "ant-design-vue";
import clone from "clone";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import {flowTypeObj,Constant} from "@/js/common";
import {
  SearchOutlined,
  EyeOutlined, FileExcelOutlined,
} from "@ant-design/icons-vue";

dayjs.locale('zh-cn');

/** 异步加载组件 **/
const ViewWorkOrder = defineAsyncComponent(() =>{
  return import('@/components/_viewWorkOrder')
})

export default {
  components: {
    FileExcelOutlined,
    SearchOutlined,
    EyeOutlined,
    ViewWorkOrder
  },
  setup(){
    const viewWorkOderId = ref(null);       //要查看工单的id
    const type = Constant.WORK_ORDER_ALL_UNFINISHED;
    const loading = ref(false);
    const isShowViewWorkOrder = ref(false);
    const viewWorkOrderRef = ref();
    const formSearchRef = ref();
    const tab_type = ref(type)
    const searchTimeRange = ref();
    const formSearchState = reactive({
      orderNo: '',
      faultOverview: '',
      type: type,
      createTimeStart: '',
      finishTimeEnd: ''
    });
    let dataSource = ref([]);
    let columns= [
      {
        title: '序号',
        dataIndex: 'index',
        width:80,
        customRender: (op) => {return ((pagination.value.current-1)*pagination.value.pageSize)+op.index+1}, //自定义渲染序号
      },{
        title: '工单号',
        dataIndex: 'orderNo',
        width: 180,
        minWidth: 50
      },{
        title: '责任人',
        dataIndex: 'reportUser',
        width: 150,
        minWidth:20
      },{
        title: '故障描述',
        dataIndex: 'faultOverview',
        ellipsis: true,
        minWidth: 100
      },{
        title: '状态',
        dataIndex: 'statusMap',
        width: 100
      },{
        title: '创建时间',
        dataIndex: 'createTime',
        width: 150,
      },{
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width:180
      }
    ];

    let pagination = ref({
      current: 1,     //当前页
      pageSize: 20,  //每页条数
      total: 1,     //数据总数
      pageSizeOptions:['2','4','6'],
      showSizeChanger:true
    });

    onMounted(()=>{
      findAllItem(1);

    })
    /**
     * 查询所有
     * @param page 页数
     */
    const findAllItem = function (page) {
      loading.value = true;
      let params = clone(formSearchState);  //克隆新的数据以免干扰formSearchState
      params.page = page;
      params.rows = pagination.value.pageSize;
      if (searchTimeRange.value) {
        params.createTimeStart = searchTimeRange.value[0].format("YYYY-MM-DD HH:mm:ss");  //将时间组件的日期格式转换成YYYY-MM-DD日期形式
        params.finishTimeEnd = searchTimeRange.value[1].format("YYYY-MM-DD HH:mm:ss");
      }
      //console.log("findAllItem searchData----",formSearchState);
      api_workOrder.findAllMyWorkOrder(params).then(res => {
        //console.log("res----",res);
        if (res?.data?.code === 0) {
          dataSource.value = [];
          const dataList = res.data.data.list;
          const _total = res.data.data.total;
          pagination.value.total = _total;
          //console.log("%%-->",1%5);
          dataList.forEach((item) => {
            //console.log("dataList-->",setFlowType(item.flowType));

            dataSource.value.push(item);
          })
        } else {
          if (res?.data?.msg) {
            message.error(res?.data?.msg + " " + res?.data?.data, 5);
          }
        }
        loading.value = false;
      });
    }
    /**
     * 设置步骤条换行显示所需的数据
     * @param flowTypes ","符号分割的字符串
     * @returns [[],[],...]
     */
    let setFlowType = function(flowTypes){
      const len = 5;  //每行显示的步数个数

      const m = [];
      let t = [];
      const ftArr = flowTypes.split(",");
      ftArr.forEach((item,index)=>{
        if ((index+1)%len===0){
          t.push(flowTypeObj[item])
          m.push(t);
          t=[];
        }else {
          t.push(flowTypeObj[item])
          if (index+1===ftArr.length){
            m.push(t);
            t=[];
          }
        }
      })
      let _minSize = 0;
      if (ftArr.length > len){
        _minSize = 5;
      }else {
        _minSize = ftArr.length%len === 0 ? len : ftArr.length%len;
      }
      const obj = {list:m,minSize:_minSize};
      return obj;
    }
    /**
     * 分页响应事件
     * @param page {@link pagination}
     */
    let onPageChange = function (page){
      findAllItem(page);
    }
    /**
     * 改变pageSize分页条数
     * @param current 当前页
     * @param size  分页条数
     */
    const onShowSizeChanger = function (current, size){
      pagination.value.current = current;
      pagination.value.pageSize = size;
    }
    /**
     * 跳到第一页
     */
    const toHomePage = ()=>{
      findAllItem(1);
      pagination.value.current = 1;
    }
    /**
     * 跳到最后页
     */
    const toEndPage = ()=>{
      findAllItem(getTotalPage());
      pagination.value.current = getTotalPage();
    }
    /**
     * 刷新当前页
     */
    const toCurrentPage = ()=>{
      findAllItem(pagination.value.current);
    }

    /**
     * 获取当前总页数
     */
    const getTotalPage =()=>{
      if (pagination.value.total === 0){
        return 1;
      }
      return pagination.value.total%pagination.value.pageSize === 0
          ? pagination.value.total/pagination.value.pageSize
          : parseInt(pagination.value.total/pagination.value.pageSize)+1;
    }

    /**
     * 搜索
     */
    const onSearch = ()=>{
      formSearchRef.value.validateFields().then(()=>{
        //console.log("onSearch  values ---",values);

        toHomePage();
      }).catch(()=>{
        //console.log("onSearch  info ---",info);
      });
    }
    const exportToExcelOfWorkOrderList = ()=>{
      const etew = message.loading('正在导出到Excel...', 0);
      let params = clone(formSearchState);  //克隆新的数据以免干扰formSearchState
      if (searchTimeRange.value) {
        params.createTimeStart = searchTimeRange.value[0].format("YYYY-MM-DD HH:mm:ss");  //将时间组件的日期格式转换成YYYY-MM-DD日期形式
        params.finishTimeEnd = searchTimeRange.value[1].format("YYYY-MM-DD HH:mm:ss");
      }

      api_workOrder.exportToExcelOfWorkOrderList(params).then(res=>{
        console.log(res)
        if (res?.data.size) {
          const downloadElement = document.createElement("a");
          const href = window.URL.createObjectURL(res.data); // 创建下载的链接
          downloadElement.href = href;
          downloadElement.download = decodeURI("未完成工单记录统计.xls"); // 下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); // 点击下载
          document.body.removeChild(downloadElement); // 下载完成移除元素
          window.URL.revokeObjectURL(href); // 释放掉blob对象
          setTimeout(etew,100); //隐藏提示
        }else {
          setTimeout(etew,100); //隐藏提示
          if (res?.data?.msg) {
            message.error(res?.data?.msg + " " + res?.data?.data, 5);
          }
        }
      });
    }

    /**
     * 查看工单
     * @param id 工单id
     */
    const showViewWorkOrder = (id)=>{
      viewWorkOderId.value = id;
      isShowViewWorkOrder.value = true;
    }
    /**
     * 查看工单窗口关闭回调
     */
    const callbackViewWorkOrder = ()=>{
      viewWorkOderId.value = null;
      isShowViewWorkOrder.value = false;
      toCurrentPage();
    }

    return{
      viewWorkOderId,
      columns,
      dataSource,
      pagination,
      onPageChange,
      onShowSizeChanger,
      formSearchRef,
      formSearchState,
      searchTimeRange,
      onSearch,
      toEndPage,
      locale,
      isShowViewWorkOrder,
      viewWorkOrderRef,
      showViewWorkOrder,
      callbackViewWorkOrder,
      setFlowType,
      flowTypeObj,
      Constant,
      tab_type,
      loading,
      exportToExcelOfWorkOrderList
    }
  }

}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.tableHeaderContainer_0{
  display: flex;
  height: 50px;
  background-color: #ffffff;
  align-items: flex-end;
  padding-bottom: 5px;
}
:deep(.ant-form-item){
  margin-bottom: 0px;
}

</style>
