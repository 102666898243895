<template>
  <div ref="container" style="height:100%;position: relative;overflow-y: auto;">
    <div style="padding: 0px;background-color: #fff">
      <div class="block" style="" >
        <div style="display: flex;box-sizing:border-box;height: 60px;" >
          <div style="flex: 1;display: flex;align-items: center;padding-left: 10px;">
            <a-form ref="formSearchRef" layout="inline"  :model="formSearchState" name="search">
              <div >
                <a-space :size="5">
                  <a-form-item name="title">
                    <a-input v-model:value="formSearchState.title" allowClear placeholder="标题" :maxlength="100" size="default" />
                  </a-form-item>
                  <a-form-item name="searchCreateTimeRange" >
                    <a-range-picker show-time v-model:value="searchCreateTimeRange" format="YYYY-MM-DD HH:mm:ss" :locale="locale" size="default"/>
                  </a-form-item>
                  <a-form-item>
                    <a-button type="primary" @click="onSearch()" size="default" style="margin-left: 0px;"><template #icon><SearchOutlined /></template>搜索</a-button>
                  </a-form-item>
                </a-space>
              </div>
            </a-form>
          </div>

          <div style="flex: 0;display: flex;justify-content: flex-end;align-items: center;padding-right: 10px;">
          </div>
        </div>
        <!-- :scroll 设置y值高度可实现表头固定滚动，整个body100vh - 头部header高度90 - FrameMain=>content Margin 5px - search 50 - 表头高度40 - 分页高度52 -->
        <a-table
            class="ant-table-striped"
            :columns="columns"
            :data-source="dataSource"
            :pagination="false"
            :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
            :bordered="true"
            size="small"
            :loading="loading"
            :scroll="{ x: 900, y: 'calc(100vh - 247px)' }"
        >
          <template #bodyCell="{ column,record }">
            <template v-if="column.dataIndex === 'operation'">
              <div style="display: flex;justify-content: left;">
                <a-button style="margin: 0px 4px;" type="link" size="small" @click="showViewKbsItem(record.id)"><template #icon><eye-outlined /></template><span style="margin-left:3px;">预览</span></a-button>
                <a-button style="margin: 0px 4px;" type="link" size="small" @click="showViewKbsApproval(record.kbsApprovalFlowId)"><template #icon><audit-outlined /></template><span style="margin-left:3px;">去审批</span></a-button>
              </div>
            </template>
            <template v-if="column.dataIndex === 'title'">
              <a-tooltip :title="record.title" placement="topLeft">{{record.title}}</a-tooltip>
            </template>
            <template v-else-if="column.dataIndex === 'status'">
              <a-badge status="success" v-if="record.status === Constant.KBS_APPROVAL_STATUS_PASSED" text='已通过' style="color: #87d068"/>
              <a-badge status="processing" v-if="record.status === Constant.KBS_APPROVAL_STATUS_PENDING" text='待审批' />
              <a-badge status="error" v-if="record.status === Constant.KBS_APPROVAL_STATUS_REJECTED" text='未通过' style="color: #f50"/>
            </template>
          </template>
        </a-table>
        <div style="padding:10px;text-align: center">
          <a-pagination v-model:current="pagination.current" :total="pagination.total" :page-size="pagination.pageSize"
                        @change="onPageChange" @showSizeChange="onShowSizeChanger" :show-total="total => `共 ${total} 条`"
                        :page-size-options="['10','20','30','40','50']" show-size-changer>
            <template #buildOptionText="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>

    </div>

    <!--    查看     -->
    <ViewKbsItem v-if="isShowViewKbsItemDialog" :kbsRecordId="viewKbsRecordId"></ViewKbsItem>
    <!-- 知识库流程 -->
    <ViewKbsApproval ref="viewKbsApprovalRef" v-if="isShowViewKbsApproval" :kbsApprovalFlowId="viewKbsApprovalFlowId" @callback="callbackViewKbsApproval"/>

  </div>
</template>

<script setup>
import {onMounted, ref, reactive, defineAsyncComponent, nextTick,} from "vue";
import {message} from "ant-design-vue";
import clone from "clone";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import {
  SearchOutlined,
  EyeOutlined,
    AuditOutlined
} from "@ant-design/icons-vue";
import api_kbsItem from "@/api/kbsItem";
import {Constant} from "@/js/common";

dayjs.locale('zh-cn');

const ViewKbsItem = defineAsyncComponent(() =>{
  return import('@/components/kbs/_viewKbsItemDialog.vue')
})
const ViewKbsApproval = defineAsyncComponent(() =>{
  return import('@/components/kbs/_viewKbsApproval.vue')
})

const loading = ref(false);
const isShowViewKbsItemDialog = ref(false);
const isShowViewKbsApproval = ref(false);
const viewKbsRecordId = ref();
const viewKbsApprovalFlowId = ref();
const viewKbsApprovalRef = ref();
const formSearchRef = ref();
const searchCreateTimeRange = ref();

const formSearchState = reactive({
  title: ''
});
let dataSource = ref([]);
let columns= [
  {
    title: '序号',
    dataIndex: 'index',
    width:80,
    customRender: (op) => {return ((pagination.value.current-1)*pagination.value.pageSize)+op.index+1}, //自定义渲染序号
  },{
    title: '标题',
    dataIndex: 'title',
    ellipsis: true,
    minWidth: 100
  },{
    title: '编辑人',
    dataIndex: 'createUser',
    width: 150,
  },{
    title: '编辑时间',
    dataIndex: 'createDate',
    width: 150,
  },{
    title: '状态',
    dataIndex: 'status',
    width: 150
  },{
    title: '操作',
    dataIndex: 'operation',
    fixed: 'right',
    width:180
  }
];

let pagination = ref({
  current: 1,     //当前页
  pageSize: 15,  //每页条数
  total: 1,     //数据总数
  pageSizeOptions:['2','4','6'],
  showSizeChanger:true
});

onMounted(()=>{
  findAllItem(1);

})

/**
 * 查询所有
 * @param page 页数
 */
const findAllItem = function (page) {
  loading.value = true;
  let params = clone(formSearchState);  //克隆新的数据以免干扰formSearchState
  params.page = page;
  params.rows = pagination.value.pageSize;
  //searchTimeRange 时间范围查询是createDateStart、createDateEnd两个时间
  if (searchCreateTimeRange.value) {
    params.createDateStart = searchCreateTimeRange.value[0].format("YYYY-MM-DD HH:mm:ss");  //将时间组件的日期格式转换成YYYY-MM-DD日期形式
    params.createDateEnd = searchCreateTimeRange.value[1].format("YYYY-MM-DD HH:mm:ss");
  }
  //console.log("findAllItem searchData----",formSearchState);
  api_kbsItem.listApprovalPending(params).then(res => {
    //console.log("res----",res);
    if (res?.data?.code === 0) {
      dataSource.value = [];
      const dataList = res.data.data.list;
      const _total = res.data.data.total;
      pagination.value.total = _total;
      //console.log("%%-->",1%5);
      dataList.forEach((item) => {
        //console.log("dataList-->",setFlowType(item.flowType));
        const obj = {id:item.kbsRecord.id,title:item.kbsRecord.title,fault:item.kbsRecord.fault,createUser:item.kbsRecord.createUser,createDate:item.kbsRecord.createDate,
          status:item.kbsApprovalFlow.status,kbsApprovalFlowId:item.kbsApprovalFlow.id};
        dataSource.value.push(obj);
      })
    } else {
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
    loading.value = false;
  });
}

/**
 * 分页响应事件
 * @param page {@link pagination}
 */
let onPageChange = function (page){
  findAllItem(page);
}
/**
 * 改变pageSize分页条数
 * @param current 当前页
 * @param size  分页条数
 */
const onShowSizeChanger = function (current, size){
  pagination.value.current = current;
  pagination.value.pageSize = size;
}
/**
 * 跳到第一页
 */
const toHomePage = ()=>{
  findAllItem(1);
  pagination.value.current = 1;
}
/**
 * 跳到最后页
 */
/*const toEndPage = ()=>{
  findAllItem(getTotalPage());
  pagination.value.current = getTotalPage();
}*/
/**
 * 刷新当前页
 */
const toCurrentPage = ()=>{
  findAllItem(pagination.value.current);
}

/**
 * 获取当前总页数
 */
/*const getTotalPage =()=>{
  if (pagination.value.total === 0){
    return 1;
  }
  return pagination.value.total%pagination.value.pageSize === 0
      ? pagination.value.total/pagination.value.pageSize
      : parseInt(pagination.value.total/pagination.value.pageSize)+1;
}*/

/**
 * 搜索
 */
const onSearch = ()=>{
  formSearchRef.value.validateFields().then(()=>{
    //console.log("onSearch  values ---",values);

    toHomePage();
  }).catch(()=>{
    //console.log("onSearch  info ---",info);
  });
}

/**
 * 查看知识库内容
 * @param id KbsRecordId
 */
const showViewKbsItem = (id)=>{
  viewKbsRecordId.value = id;
  isShowViewKbsItemDialog.value = false;
  nextTick(()=>{
    isShowViewKbsItemDialog.value = true;
  })
}
/**
 * 查看知识库流程
 * @param id KbsApprovalFlowId
 */
const showViewKbsApproval = (id)=>{
  viewKbsApprovalFlowId.value = id;
  isShowViewKbsApproval.value = false;
  nextTick(()=>{
    isShowViewKbsApproval.value = true;
  })
}
/**
 * 审批流程弹窗关闭后回调
 */
const callbackViewKbsApproval = ()=>{
  viewKbsRecordId.value = null;
  viewKbsApprovalFlowId.value = null;
  isShowViewKbsApproval.value = false;
  toCurrentPage();
}

</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}

.block{
  box-sizing:border-box;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
