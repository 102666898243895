<template>
  <div ref="container" style="height:100%;position: relative;overflow: hidden">
    <div style="background-color: #fff;">

      <div style="display: flex;">
        <div style="margin: auto;padding: 50px;width: 700px">
          <a-alert v-if="!licenseEntity.expireTime" type="error" :message="licenseErrMsg" banner />
          <div v-else style="background-color: #f8f8f8;padding: 16px;">
            <div><span style="font-weight: bold;">授权主体</span>：{{ licenseEntity.data }}</div>
            <div><span style="font-weight: bold;">到期日期</span>：{{ licenseEntity.expireTime }}</div>
          </div>

          <div style="padding: 16px 0;">
            <a-button type="primary" @click="isShowActivate = !isShowActivate">
              {{ !licenseEntity.expireTime ? '激活软件' : '重新激活' }}
            </a-button>
          </div>

          <div v-if="isShowActivate" style="background-color: #f8f8f8;padding: 16px;">
            <div v-if="licenseEntity.expireTime"><a-alert  message="操作前请先备份 license.txt 文件，避免无效的 license 覆盖导致软件无法使用！" banner /></div>
            <div>
              <a-tabs v-model:activeKey="type">
                <a-tab-pane key="1">
                  <template #tab>
                  <span>
                    <VerifiedOutlined />
                    license文件
                  </span>
                  </template>
                </a-tab-pane>
                <a-tab-pane key="2">
                  <template #tab>
                  <span>
                    <CodeOutlined />
                    激活码
                  </span>
                  </template>
                </a-tab-pane>
              </a-tabs>
            </div>
            <div>
              <a-form ref="formSetRef" :model="formState" @finish="onFinish" name="add">
                <a-form-item v-if="type === '1'" :rules="[{ required: true, message: '请上传license文件',trigger:'blur' }]">
                  <a-upload :file-list="fileList" :before-upload="beforeUpload" @remove="handleRemove" :max-count="5">
                    <a-button><upload-outlined></upload-outlined>选择文件</a-button>
                  </a-upload>
                </a-form-item>

                <a-form-item v-if="type === '2'" name="cdk" :rules="[{ required: true, message: '请输入激活码',trigger:'blur' },{ max:5000, message: '激活码字符长度超限',trigger:'blur' }]">
                  <a-textarea v-model:value="formState.cdk" size="default" show-count :rows="20"  />
                </a-form-item>

                <div style="text-align: center;">
                  <a-button type="primary" html-type="submit" :loading="loading">激 活</a-button>
                </div>
              </a-form>
            </div>
          </div>


        </div>
      </div>


    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {UploadOutlined,VerifiedOutlined,CodeOutlined} from "@ant-design/icons-vue";
import {message} from "ant-design-vue";
import {fileUpload} from "@/js/common";
import api_licensetSet from "@/api/licenseSet";

const loading = ref(false);
const formSetRef = ref();
const isShowActivate = ref(false);
const type = ref('1');
const formState = ref({
  cdk:""
});
const licenseErrMsg = ref("");
const licenseEntity = ref({
  expireTime:'',
  data:''
});
const fileList = ref([]);

onMounted(()=>{
  viewLicenseInfo();
});

/**
 * 文件上传前的过滤
 * @param file file
 * @returns {boolean}
 */
const beforeUpload = file => {
  if (fileList.value.length+1 > 1){
    message.error("只能上传一个文件");
    return false;
  }

  if (file.type !== "text/plain"){
    message.error(`仅支持 .txt 后缀的文本类型`);
    return false;
  }
  const flg = fileUpload.isAllowedFileSize(file.size);
  if (!flg){
    message.error(`文件过大，请限制容量在5M以内`);
    return false;
  }

  fileList.value.push(file);
  return false;
};
/**
 * 文件上传列表中的删除操作
 * @param file file
 */
const handleRemove = file => {
  const index = fileList.value.indexOf(file);
  const newFileList = fileList.value.slice();
  newFileList.splice(index, 1);
  fileList.value = newFileList;
};
/**
 * 提交，根据 license文件 或 激活码 处理不同的api接口
 */
const onFinish = () =>{
  formSetRef.value.validateFields().then(()=>{
    const formData = new FormData();

    if (type.value === '1'){
      if (fileList.value.length === 0){
        message.error("请添加 license.txt 文件");
        return false;
      }

      fileList.value.forEach((item)=>{
        formData.append('file', item);
      })

      loading.value = true;
      api_licensetSet.activateFile(formData).then(res=>{
        if(res?.data?.code === 0){
          viewLicenseInfo();
          isShowActivate.value = false;
        }else {
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loading.value = false;
      });

    }else if (type.value === '2'){
      for(let key  in formState.value){  //遍历对象key,value
        formData.append(key,formState.value[key]);
      }

      loading.value = true;
      api_licensetSet.activateCode(formData).then(res=>{
        if(res?.data?.code === 0){
          viewLicenseInfo();
          isShowActivate.value = false;
        }else {
          if (res?.data?.msg){
            message.error(res?.data?.msg+" "+res?.data?.data,5);
          }
        }
        loading.value = false;
      });
    }
  }).catch(()=>{
    //console.log("onSaveItemOK  info ---",info);
  });

}
/**
 * 获取 license 信息
 */
const viewLicenseInfo = ()=>{
  licenseEntity.value.data = "";
  licenseEntity.value.expireTime = "";
  licenseErrMsg.value = "";

  api_licensetSet.getLicenseInfo().then(res=>{
    if(res?.data?.code === 0){
      licenseEntity.value.data = res.data.data?.data;
      licenseEntity.value.expireTime = res.data.data?.expireTime;

      message.success("已激活成功",3);
    }else {
      if (res?.data?.msg){
        licenseErrMsg.value = res?.data?.msg+" "+res?.data?.data;
        //message.error(res?.data?.msg+" "+res?.data?.data,5);
      }
    }
  });
}

</script>

<style scoped>

</style>